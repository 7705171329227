import {bindable, customElement, computedFrom} from "aurelia-framework";
import {UrlUtils} from "../../utilities/url-utils";

import "./pagination.less";

@customElement('sio-pagination')
export class Pagination {
    @bindable conditions;
    @bindable sort;

    @bindable offset;
    @bindable limit;
    @bindable data;

    @bindable change;
    @bindable loadWithCount;

    @computedFrom('offset', 'limit', 'data')
    get pagination() {
        const maxPagesCountInOneDirection = 3;
        const currentPage = Math.floor(this.offset / this.limit) + 1;
        const firstItem = (currentPage - 1) * this.limit + 1;
        const lastItem = firstItem + (this.data?.items?.length ?? 1) - 1;

        const nextItem = this.limit <= this.data?.items?.length;

        let pagination = {
            offset: this.offset,
            limit: this.limit,
            currentPage: currentPage,
            firstPage: 1,
            firstItem: firstItem,
            lastItem: lastItem,
            nextItem: nextItem,
            hasItems: this.data?.items.length ?? 0
        };

        let counterPrevious = pagination.currentPage - 1;
        let previousPages = [];
        while (counterPrevious >= 1 && previousPages.length < maxPagesCountInOneDirection) {
            previousPages.push(counterPrevious);
            counterPrevious--;
        }
        pagination.previousPages = previousPages.reverse();

        if (pagination.currentPage > maxPagesCountInOneDirection + 1) {
            pagination.showThreeDotsPrev = true;
        }

        return pagination;
    }

    pageUrl(index, conditions, sort, disabled)
    {
        if (index < this.pagination.firstPage || disabled) {
            return 'javascript:void(0);'
        }

        return UrlUtils.upsertQueryParamValue(window.location.href, "offset", (index - 1) * this.limit, false);
    }

    changePage(index, disabled)
    {
        if (index < this.pagination.firstPage || disabled) {
            return;
        }

        this.change((index - 1) * this.limit);
    }
}
